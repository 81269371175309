// apiPath dictionary
const APIS = {
	'pro-loc.mijnmooiestraat.vlaanderen:3000': 'api-staging.mijnmooiestraat.vlaanderen',
	'pro-loc.mijnmooiestraat.vlaanderen.local': 'api-develop.mijnmooiestraat.vlaanderen',
	'pro-loc.mijnmooiestraat.vlaanderen': 'api-staging.mijnmooiestraat.vlaanderen',
	'pro-loc.mijnmooiestraat.test:3000': 'api.mijnmooiestraat.test',
	'pro-loc.mijnmooiestraat.test': 'api.mijnmooiestraat.test',
	'pro-develop.mijnmooiestraat.vlaanderen': 'api-develop.mijnmooiestraat.vlaanderen',
	'pro-staging.mijnmooiestraat.vlaanderen': 'api-staging.mijnmooiestraat.vlaanderen',
	'pro-beta.mijnmooiestraat.vlaanderen': 'api-beta.mijnmooiestraat.vlaanderen',
	'pro.mijnmooiestraat.vlaanderen': 'api.mijnmooiestraat.vlaanderen',
	'pro-rc.mijnmooiestraat.vlaanderen': 'api-beta.mijnmooiestraat.vlaanderen',

};

const GoogleApiKey = {
	"pro-loc.mijnmooiestraat.vlaanderen:3000": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro-loc.mijnmooiestraat.vlaanderen.local": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro-loc.mijnmooiestraat.vlaanderen": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro-loc.mijnmooiestraat.test:3000": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro-loc.mijnmooiestraat.test": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro-develop.mijnmooiestraat.vlaanderen": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro-staging.mijnmooiestraat.vlaanderen": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro-beta.mijnmooiestraat.vlaanderen": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro.mijnmooiestraat.vlaanderen": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
	"pro-rc.mijnmooiestraat.vlaanderen": "AIzaSyCWHSDDWMdT7Ab8_zybMBZifBSm0CSYpD8",
};

const GoogleTagManagerId = {
	"pro-loc.mijnmooiestraat.vlaanderen": "UA-158716364-1",
	"pro-loc.mijnmooiestraat.test": "UA-158716364-1",
	"pro-develop.mijnmooiestraat.vlaanderen": "UA-158716364-1",
	"pro-staging.mijnmooiestraat.vlaanderen": "UA-158716364-1",
	"pro-beta.mijnmooiestraat.vlaanderen": "UA-158716364-1",
	"pro.mijnmooiestraat.vlaanderen": "UA-128872212-1",
	"pro-rc.mijnmooiestraat.vlaanderen": "UA-158716364-1",
};

const FAQ = {
	"pro-loc.mijnmooiestraat.vlaanderen": "https://faq-loc.mijnmooiestraat.vlaanderen",
	"pro-loc.mijnmooiestraat.test": "https://faq-loc.mijnmooiestraat.test",
	"pro-develop.mijnmooiestraat.vlaanderen": "https://faq-develop.mijnmooiestraat.vlaanderen",
	"pro-staging.mijnmooiestraat.vlaanderen": "https://faq-staging.mijnmooiestraat.vlaanderen",
	"pro-beta.mijnmooiestraat.vlaanderen": "https://faq.mijnmooiestraat.vlaanderen",
	"pro.mijnmooiestraat.vlaanderen": "https://faq.mijnmooiestraat.vlaanderen",
	"pro-rc.mijnmooiestraat.vlaanderen": "https://faq.mijnmooiestraat.vlaanderen",
};

// eslint-disable-next-line func-names
const Config = (function () {
	const Config = {
		app_client_id: '373d13ed-89bd-49e8-bfcf-e7a30ca572d2',
		app_client_secret: 'IOuzWeqrLwHZpQ7kGdHgju14',
		version: '2.01',
		// Develop: 0cb107c08127706a1839
		pusher_authkey: document.location.host.includes('pro-loc') || document.location.host.includes('pro-develop') ? '80859bab10d9e42f7ac5' : (document.location.host.includes('pro-staging') ? '80859bab10d9e42f7ac5' : '901ec1c86ebda1f0af0a'),
		pusher_cluster: 'eu',
		baseUrl: `//${(() => APIS[document.location.host] || APIS['pro-staging.mijnmooiestraat.vlaanderen'])()}/`,
		isBeta: (()=>{ return window.location.host.includes('pro-beta') })(),
		paths: {
			binTypeImageId: {
				"77444b01-dc0d-4ad3-b01b-2d01242b42a7": "vuilnisbak/andere/andere",
				"202ef028-c6fd-446d-a8d4-b4a612baf3d8": "glascontainer/glascontainer",
				"4adc531a-542e-4aae-b684-ab2d8e6e2689": "sorteerstraat/sorteerstraat",
				"265c418e-b2ac-4458-bcef-5664af97214d": "vuilnisbak/vuilnisbak",
				"a9649bd1-f8f9-4919-b8c2-b69f6799b9c1": "textielcontainer/textielcontainer",
				"6221c771-3032-4f52-ab29-7b856a40743a": "peuken/peuken",
				"3829135b-a0d8-43f1-993c-530f412821ac": "hondenpoep/hondenpoep",
				"a05caf8e-39ba-4780-9d6c-4456182667ba": "vuilnisbak/rest/rest",
				"83072bed-bdef-4ce3-b5ff-7602a78ef952": "vuilnisbak/PMD/PMD",
				"01a23d78-75de-4435-9a51-f91fe0a38522": "vuilnisbak/pizzadozen/pizzadozen",
				"0ef59918-44db-4dff-b944-f0cd3510bda4": "vuilnisbak/andere/andere",
				"04a3c49c-cee0-440c-a8f3-a24e57fd1e41": "sorteerstraat/rest/rest",
				"61b9ed63-f8f6-4b0a-b0b4-d4e1ab8167f1": "sorteerstraat/PMD/PMD",
				"6fa806db-2b45-46dd-9af4-d2624ac34797": "sorteerstraat/papier-karton/karton",
				"3c3841a4-f27d-4cd7-b837-8409de0e100c": "sorteerstraat/glas/glas",
				"99e53a04-ee02-4d33-9d3c-f6319b92e000": "sorteerstraat/GFT/GFT",
				"a4bedaac-de16-48c3-b8c5-5d318fd82ce9": "glascontainer/gekleurd/gekleurd",
				"d6bb15f0-7fd2-4a31-b329-e93722b1c298": "glascontainer/wit/wit",
				"8cb459a6-ebe8-491d-afa7-8438f1a1d21b": "glascontainer/beide/beide",
			},
			binTypeMarkersId: {
				"77444b01-dc0d-4ad3-b01b-2d01242b42a7": "afvalpunten/markers/vuilnisbak_marker--resized", // andere
				"265c418e-b2ac-4458-bcef-5664af97214d": "afvalpunten/markers/vuilnisbak_marker--resized", // vuilnisbak
				"a05caf8e-39ba-4780-9d6c-4456182667ba": "afvalpunten/markers/vuilnisbak_marker--resized", // vuilnisbak / rest
				"83072bed-bdef-4ce3-b5ff-7602a78ef952": "afvalpunten/markers/vuilnisbak_marker--resized", // vuilnisbak / PMD
				"01a23d78-75de-4435-9a51-f91fe0a38522": "afvalpunten/markers/vuilnisbak_marker--resized", // vuilnisbak /pizzadozen
				"0ef59918-44db-4dff-b944-f0cd3510bda4": "afvalpunten/markers/vuilnisbak_marker--resized", // vuilnisbak / andere
				"202ef028-c6fd-446d-a8d4-b4a612baf3d8": "afvalpunten/markers/glascontainer_marker--resized", // glascontainer
				"a4bedaac-de16-48c3-b8c5-5d318fd82ce9": "afvalpunten/markers/glascontainer_marker--resized", // glascontainer / gekleurd
				"d6bb15f0-7fd2-4a31-b329-e93722b1c298": "afvalpunten/markers/glascontainer_marker--resized", // glascontainer / wit
				"8cb459a6-ebe8-491d-afa7-8438f1a1d21b": "afvalpunten/markers/glascontainer_marker--resized", // glascontainer /beide
				"4adc531a-542e-4aae-b684-ab2d8e6e2689": "afvalpunten/markers/sorteerstraat_marker--resized", // sorteerstraat
				"04a3c49c-cee0-440c-a8f3-a24e57fd1e41": "afvalpunten/markers/sorteerstraat_marker--resized", // sorteerstraat / rest
				"61b9ed63-f8f6-4b0a-b0b4-d4e1ab8167f1": "afvalpunten/markers/sorteerstraat_marker--resized", // sorteerstraat / pmd
				"6fa806db-2b45-46dd-9af4-d2624ac34797": "afvalpunten/markers/sorteerstraat_marker--resized", //sorteerstraat / paper-karton
				"3c3841a4-f27d-4cd7-b837-8409de0e100c": "afvalpunten/markers/sorteerstraat_marker--resized", // sorteerstraat / glas
				"99e53a04-ee02-4d33-9d3c-f6319b92e000": "afvalpunten/markers/sorteerstraat_marker--resized", //sorteerstraat / gft
				"a9649bd1-f8f9-4919-b8c2-b69f6799b9c1": "afvalpunten/markers/textielcontainer_marker--resized", // textielcontainer
				"6221c771-3032-4f52-ab29-7b856a40743a": "afvalpunten/markers/peuken_marker--resized", //peuken
				"3829135b-a0d8-43f1-993c-530f412821ac": "afvalpunten/markers/hondenpoep_marker--resized", // hondenpoep
			},

			filterIconsForTypeCollection: {
				"06447ac8-654e-450f-a651-2e0c2d2f6015": "images/icons/speaker--colored", // Melding Type
				"04eb6868-5c97-4388-9b8b-a57997648f75": "images/icons/avatar-placeholder", // Burger melding Type
				"727e03f0-1d90-4c38-84b5-cb4d2b2f0fb8": "images/icons/app", // App Melding Type
			},

			markers: {
				routeStart: "volunteers/markers/routeStart.svg",
				routeFinish: "volunteers/markers/routeFinish.svg",
				collectedBags: "volunteers/markers/collected-bags.svg",
			},

			imageHost: "",
			community: "jsonapi/node/community",
			jwt: "jwt/token",
			login: "oauth/token",
			logout: "user/logout",
			requestPassword: "user/lost-password",
			resetPassword: "user/lost-password-reset",
			user: "jsonapi/user/user",
			permissions: "jsonapi/user/user",
			incidents: "jsonapi/node/incident",
			incidentMarkers: "api/incidents",
			incident: "jsonapi/node/incident",
			incidentCandidates: "jsonapi/node/incident_candidate",
			incidentCandidate: "jsonapi/node/incident_candidate",
			incidentReportTypes: "jsonapi/taxonomy_term/incident_report_type",
			incidentDeclineReasons: "jsonapi/taxonomy_term/incident_decline_reason",
			incidentHistory: "api/incident/history",
			remark: "jsonapi/remark/remark",
			files: "jsonapi/file/file",
			incidentImages: "jsonapi/node/incident/field_incident_images",
			incidentCandidateImages: "jsonapi/node/incident_candidate/field_incident_candidate_image",
			categories: "jsonapi/taxonomy_term/incident_categories",
			statuses: "jsonapi/taxonomy_term/incident_status",
			bin: "jsonapi/node/bin",
			binSurfaces: "jsonapi/taxonomy_term/bin_surfaces",
			binMaterials: "jsonapi/taxonomy_term/bin_materials",
			binTypes: "jsonapi/taxonomy_term/bin_types",
			binLocationTypes: "jsonapi/taxonomy_term/bin_location_types",
			binColors: "jsonapi/taxonomy_term/bin_colors",
			binActions: "jsonapi/taxonomy_term/bin_action",
			binEmptyingFrequencies: "jsonapi/taxonomy_term/bin_emptying_frequencies",
			users: "api/community/users",
			binHistory: "api/bin/history",
			binAnalyse: "api/bin/analyse",
			binMarkers: "api/bins",
			binMarkersSmall: "api/bins/small",
			binsSmall: "api/bins/small",
			binHistoryChanges: "jsonapi/bin_history/bin_changes",
			binRemark: "jsonapi/bin_remark/bin_remark",
			binRemarkStatuses: "jsonapi/taxonomy_term/bin_remark_status",
			binRemarks: "api/bin/remarks",
			binQr: "api/bins/qr",
			measurements: "api/measurements",
			measurementJSON_API: "jsonapi/node/measurement",
			measurementRemarkType: "jsonapi/measurement_remark/m_b_damage_remark",
			measurementDamage: "jsonapi/taxonomy_term/measurement_damage_amounts",
			measurementDamageType: "jsonapi/taxonomy_term/measurement_damage_type",
			measurementAddition: "jsonapi/taxonomy_term/measurement_additional_dump",
			measurementsSummary: "api/measurements/summary",
			geoBins: "api/geo-bins",
			reportBins: "api/report/bins",
			dataBins: "api/report/bins/measurements",
			dataBinsLocation: "api/report/bins/measurements/location",
			dataBinMeasurements: "api/bin/measurements",
			incidentOverview: "api/incident/overview",
			reportIncidentsCategories: "api/report/incidents/categories",
			reportIncidentsDetail: "api/report/incidents/detail",
			reportIncidentsStart: "api/report/incidents",
			volunteers: "api/pro/volunteers",
			volunteer: "api/pro/volunteer",
			activities: "api/pro/activities",
			activity: "api/pro/activities",
			activityMapPdf: "pdf/volunteer-activity-map",
			activityMarkers: "api/pro/activities/map",
			activityHeatMapData: "api/pro/activities/heatmap",
			incidentDeletePermission: "api/access/node/incident/delete",
			binDeletePermission: "api/access/node/bin/delete",
			measurementDeletePermission: "api/access/node/measurement/delete",
			binGroupDeletePermission: "api/access/node/bin_group/delete",
			binMeasuringPeriodDeletePermission: "api/access/node/bin_measuring_period/delete",
			projectDeletePermission: "api/access/node/monitoring_project/delete",
			monitorParties: "api/pro/monitoring/parties",
			cleaningMethods: "api/pro/monitoring/cleaning-methods",
			countingMethods: "api/pro/monitoring/counting-methods",
			countingUnits: "api/pro/monitoring/counting-units",
			cleaningFrequencies: "api/pro/monitoring/cleaning-frequencies",
			monitorFractions: "api/pro/monitoring/fractions",
			stripTypes: "api/pro/monitoring/measuring-strip-types",
			projects: "api/pro/monitoring/projects",
			monitorLocationTypes: "api/pro/monitoring/location-types",
			projectImage: "api/pro/monitoring/projects/addimage",
			project: "api/pro/monitoring/projects",
			measures: "api/pro/monitoring/measures",
			projectStatuses: "api/pro/monitoring/project-statuses",
			acProjects: "api/pro/monitoring/projects/autocomplete",
			projectMeasurement: "api/pro/monitoring/measurements",
			projectMeasurementTypes: "api/pro/monitoring/measurement-types",
			measurePillars: "api/pro/monitoring/pillars",
			measureEfforts: "api/pro/monitoring/measure-efforts",
			momoApi: "api/access/node/monitoring_project/create",
			exportReportsApi: "api/access/check/_reporting_enabled",
			projectImpacts: "api/pro/monitoring/project-impacts",
			mapProjects: "api/pro/monitoring/projects?format=map",
			projectRapportGFractions: "report/global-fractions",
			projectRapportStripFractions: "report/strip-fractions",
			projectRapportEvolutionFractions: "report/strip-fractions-evolution",
			projectRapportEvolutionCompare: "report/strip-baseline-impact-evolution",
			projectRapportEvolutionTotals: "report/strip-totals-evolution",
			c_binLocationTypes: "api/pro/bins/location-types",
			c_binMaterials: "api/pro/bins/materials",
			c_binSurfaces: "api/pro/bins/surfaces",
			c_binTypes: "api/pro/bins/types",
			c_binDamageAmounts: "api/pro/bins/damage-amounts",
			c_binRemarkStatuses: "api/pro/bins/remark-statuses",
			c_binActions: "api/pro/bins/actions",
			c_binColors: "api/pro/bins/colors",
			c_binEmptyingFrequencies: "api/pro/bins/emptying-frequencies",
			additionalDumpStatuses: "api/pro/bins/measurements/additional-dump-statuses",
			dumpStatuses: "api/pro/bins/measurements/dump-statuses",
			fillDegrees: "api/pro/bins/measurements/fill-degrees",
			countingFormExport: "pdf/counting-form",
			measureExport: "pdf/measure-export",
			projectExport: "pdf/project",
			experienceScansExport: "pdf/project-experience-scans",
			binGroupDetailExport: "pdf/bin-group-detail",
			experienceScansDeletePermission: "api/access/node/experience_scan/delete",
			projectComparisonExport: "pdf/project-comparison",
			c_incidentCategories: "api/pro/incidents/categories",
			c_incidentStatuses: "api/pro/incidents/statuses",
			c_incidentReportTypes: "api/pro/incidents/report-types",
			c_incidentTeams: "api/pro/incidents/teams",
			c_incidentDeclineReasons: "api/pro/incidents/decline-reasons",
			c_incident: "api/pro/incidents",
			c_postalCodes: "api/pro/postal-codes",
			c_bins: "api/pro/bins",
			binsGeoJson: "api/pro/bins/geojson",
			c_binMarkers: "api/pro/bins/map",
			c_user: "api/pro/user",
			termsOfUse: "api/pro/terms-of-use",
			litterStatuses: "api/pro/bins/measurements/litter-statuses",
			heatmapFiles: "api/pro/heatmap-files",
			generalExportReports: "api/pro/exports/key-figures",
			notifications: "api/pro/notifications",
			projectExperienceScan: "api/pro/monitoring/experience-scans",
			projectExperienceScans: "api/pro/monitoring/experience-scans",
			projectExperienceScansAnswers: "api/pro/monitoring/experience-scan-answers",
			monitoringReportGeneral: "api/pro/monitoring/report/general",
			monitoringReportMeasuringDesign: "api/pro/monitoring/report/measuring-design",
			monitoringReportResults: "api/pro/monitoring/report/results",
			binGroups: "api/pro/bin-groups",
			globalExportReports: "api/pro/exports/global",
			localExportReports: "api/pro/exports/local",
			provinces: "api/pro/provinces",
			intermunicipal: "api/pro/intermunicipal-associations",
			referenceRegions: "api/pro/reference-regions",
			belfiusClusters: "api/pro/belfius-clusters",
			acCommunities: "api/pro/local-administrations",
			measuringPeriodSubjects: "api/pro/bins/measuring-period-subjects",
			measuringPeriods: "api/pro/bin-measuring-periods",
			acUsers: "api/pro/users",
			groupAdminAccess: 'api/access/node/group/admin/${id}',
			c_groups: 'api/pro/groups',
			c_user_groups: 'api/pro/user/groups',
			c_groupTypes: 'api/pro/groups/types',
			c_posts: 'api/pro/posts',
			c_events: 'api/pro/events',
			c_user_events: 'api/pro/user/events',
			eventsOverviewExport: 'pdf/events',
			eventsUserOverviewExport: 'pdf/my-events',
			groupsOverviewExport: 'pdf/groups',
			userGroupsOverviewExport: 'pdf/my-groups',
			c_binMeasurements: 'api/pro/bins/measurements',
			c_binMeasurementsSummary: 'api/pro/bins/measurements-summary',
			c_binMeasurementsComparison: 'api/pro/bins/measurements/period-comparison',
		},

		getUrl(key) {
			return Config.baseUrl + Config.paths[key];
		},

		routes: {
			login: "/",
			home: "/home",
			meta: {
				title: "Mijn Mooie Straat - Mooimakers",
			},
			password: {
				change: "/password/change",
				reset: "/password/reset",
			},
			incidents: {
				home: "/home#meldingen",
				new: "/incident-add",
				end: "/incident-end",
				overview: "/incident-list",
				map: "/incident-map",
				charts: "/meldingen-rapportage",
				detail: "/incident/",
				duplicate: "/incident-duplicate/",
				registrationStart: "/incident-registration/:communityId/start",
				registrationForm: "/incident-registration/:communityId/form",
				registrationEnd: "/incident-registration/end",
			},
			measurements: {
				new: "/afvalpunten/metingen/toevoegen",
				end: "/metingen/meting-aangemaakt",
				overview: "/afvalpunten/metingen/overzicht",
				detail: "/measurement/",
				charts: "/rapportage",
			},
			bins: {
				home: "/home#afvalpunten",
				new: "/afvalpunten/nieuw",
				end: "/afvalpunten/afvalpunt-aangemaakt",
				overview: "/afvalpunten/inventaris",
				overviewInventory: "/afvalpunten/inventaris#inventaris",
				overviewGroups: "/afvalpunten/inventaris#groepen",
				overviewMeasuringPeriods: "/afvalpunten/inventaris#meetperiodes",
				mapOverview: "/afvalpunten/map",
				detail: "/afvalpunt/",
				groupWizard: "/afvalpunten/groepen/groep-aanmaken",
				groupEnd: "/afvalpunten/groepen/groep-aangemaakt",
				groupDetail: "/afvalpunten/groepen/",
				measuringPeriodWizard: "/afvalpunten/meetperiodes/meetperiode-aanmaken",
				measuringPeriodEnd: "/afvalpunten/meetperiodes/meetperiode-aangemaakt",
				measuringPeriodDetail: "/afvalpunten/meetperiodes/",
			},
			volunteers: {
				home: '/home#vrijwilligers',
				active: '/vrijwilligers/actieve-vrijwilligers',
				activities: '/vrijwilligers/opruimsessies',
				activity: '/vrijwilligers/opruimsessie/',
				map: '/vrijwilligers/map',
				charts: '/vrijwilligers/rapportage',
				settings: '/vrijwilligers/instellingen',
				detail: '/vrijwilliger/',
				eventsOverview: '/opruimacties',
				eventsDetail: '/opruimactie/',
				groupsOverview: '/groepen',
				groupsDetail: '/groep/',
				postWizard: '/posts/nieuw',
				postDetail: '/post/',
				activityMapDetail: "/image/volunteer-activity-map/"
			},
			projects: {
				home: "/home#monitoring",
				new: "/projecten/nieuw",
				end: "/projecten/project-aangemaakt",
				detail: "/project/",
				overview: "/projecten",
				locationWizard: "/locatie-aanpassen",
				map: "/projecten-map",
			},
			projectMeasurements: {
				new: "/project/meting/toevoegen",
				detail: "/project-meting/",
				end: "/project/meting/meting-aangemaakt",
			},
			projectExperienceScans: {
				new: "/project/belevingsscan/toevoegen",
				end: "/project/belevingsscan/belevingsscan-aangemaakt",
				detail: "/project/belevingsscan/",
			},
			measure: {
				preview: "/maatregel/preview/",
				overview: "/projecten",
			},
			pdfs: {
				countingForm: '/pdf/counting-form/',
				project: '/pdf/project/',
				measureExport: '/pdf/measure-export/',
				experienceScansExport: '/pdf/experience-scans-export/',
				projectComparisonExport: '/pdf/project-comparison',
				binGroupDetailExport: '/pdf/bin-group-detail/',
				eventsOverviewExport: '/pdf/events',
				eventsUserOverviewExport: '/pdf/my-events',
				groupsOverviewExport: '/pdf/groups',
				userGroupsOverviewExport: '/pdf/my-groups',
				binMeasurementComparison: '/pdf/compare-bin-measuring-periods',
			},
			reporting: {
				home: "/rapportage-dashboard",
				incidents: "/rapportage-dashboard/meldingen",
				bins: "/rapportage-dashboard/vuilnisbakken",
				monitoring: "/rapportage-dashboard/projecten",
				exports: "/rapportage/export-uitvoeren",
				exportsEnd: "/rapportage/export-uitgevoerd",
			},
			monitoring: {
				reports: "/monitoring-rapportage",
				comparison: "/projecten-vergelijken",
			},
		},

		urlsToExclude: [
			"*/user/*",
			"*/admin/*",
			"*/node/add*",
			"*/node/edit*",
			"*/run-cron*",
			"*/update*",
			"*/devel*",
			"/disclaimer",
			"/burgermelding/disclaimer",
		],

		features: {
			incidents: {
				isActive: true,
				views: {
					creation: true,
					map: true,
					overview: true,
					charts: true,
				},
			},
			garbageBins: {
				isActive: true,
				views: {
					creation: true,
					measurements: true,
					overview: true,
					charts: true,
				},
			},
		},

		apiDateFormat: "YYYY-MM-DD",
		dateFormat: "DD.MM.YYYY",
		dateTimeFormat: "DD.MM.YYYY - HH:mm:ss",
		timeFormat: "HH:mm:ss",
		locales: "nl-BE",

		isMobileAt: 720,
		isTabletAt: 960,

		storageKeys: {
			session: "MM-SESSION",
			refreshToken: "MM-TOKEN",
			user: "MM-USER",
			targetAfterLogin: "MM-TARGET-AL",
		},

		history: {
			max: 100,
		},

		jwt: {
			expiration: 3300000, // less than an hour, jwt tokens expire every hour at api level, it needs refresh before that happens
		},

		gtag: {
			id: GoogleTagManagerId[document.location.host],
			events: {
				INCIDENT_START: "incident_creation_start",
				INCIDENT_END: "incident_creation_end",
				INCIDENT_REGISTRATION_START: "incident_civilian_registration_start",
				INCIDENT_REGISTRATION_END: "incident_civilian_registration_end",
				BIN_MEASUREMENT_START: "garbage_bin_measurement_creation_start",
				BIN_MEASUREMENT_END: "garbage_bin_measurement_creation_end",
				BIN_START: "garbage_bin_creation_start",
				BIN_END: "garbage_bin_creation_end",
			},
		},

		geoJsonGmap: {
			polygon: "POLYGON",
			polyline: "LINESTRING",
		},

		spiderfier: {
			defaultOptions: {
				keepSpiderfied: true,
				circleSpiralSwitchover: Infinity,
				circleFootSeparation: 45,
			},
		},
		faq: {
			href: FAQ[document.location.host],
		},
		maps: {
			key: GoogleApiKey[document.location.host],
			defaultOptions: {
				zoom: 9.6,
				// clickableIcons:    false,
				disableDefaultUI: true,
				draggable: true,
				fullscreenControl: true,
				gestureHandling: "greedy",
				scrollwheel: false,
				zoomControl: true,
				streetViewControl: true,
				mapTypeControl: true,
				center: {
					lat: 51.10581098530057,
					lng: 4.381534371142152,
				},
				styles: [
					{
						featureType: "administrative",
						elementType: "all",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
					{
						featureType: "administrative",
						elementType: "geometry",
						stylers: [
							{
								color: "#e5e3df",
							},
						],
					},
					{
						featureType: "landscape.man_made",
						elementType: "all",
						stylers: [
							{
								color: "#e5e3df",
							},
						],
					},
					{
						featureType: "landscape.natural",
						elementType: "all",
						stylers: [
							{
								color: "#e5e3df",
							},
						],
					},
					{
						featureType: "poi.attraction",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.business",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.business",
						elementType: "labels",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.government",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.medical",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "all",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#afca89",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#ffffff",
							},
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "labels",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.place_of_worship",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.school",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "poi.sports_complex",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#ffffff",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#ffffff",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "labels",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "road.highway.controlled_access",
						elementType: "all",
						stylers: [
							{
								visibility: "on",
							},
						],
					},
					{
						featureType: "transit.line",
						elementType: "all",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						featureType: "transit.station",
						elementType: "all",
						stylers: [
							{
								visibility: "on",
							},
							{
								weight: "1.00",
							},
						],
					},
					{
						featureType: "water",
						elementType: "all",
						stylers: [
							{
								color: "#a0d3d3",
							},
						],
					},
				],
			},

			libraries: ["places", "geometry", "visualization", "drawing"],

			geo: {
				region: "BE",
				componentRestrictions: {},
			},

			infoWindow: {
				disableAutoPan: false,
			},
		},

		regex: {
			email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			phone: /(?:((0{1,2}|\+)[0-9]{1,2})\ ?)(?:[0-9]{2,3}\ ?)(?:[0-9]{2}\ ?){2,3}/,
			lettersNumbersSpaces: /[^a-z0-9 ]/gi,
		},

		pagination: {
			default: {
				maxItems: 10,
			},

			incidents: {
				maxItems: 50,
			},
		},

		charts: {
			defaultPieChartOptions: {
				legend: {
					position: "bottom",
					labels: {
						boxWidth: 7.5,
						usePointStyle: true,
						padding: 16,
					},
				},
				cutoutPercentage: 40,
				tooltips: {
					enabled: false,
					yAlign: "top",
					callbacks: {},
				},
				plugins: {
					datalabels: {
						display: (context) => !!context.dataset.data[context.dataIndex],
						font: {
							family: "'Flanders Art Sans', sans-serif",
							size: 16,
							weight: "bold",
						},
					},
				},
			},
			defaultBarChartOptions: {
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false,
							},
							ticks: {
								maxRotation: 50,
								minRotation: 50,
							},
						},
					],
					yAxes: [
						{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 100,
								stepSize: 25,
								callback: (value) => `${value}%`,
							},
						},
					],
				},
				maintainAspectRatio: false,
				legend: {
					position: "bottom",
					onClick: () => {},
					labels: {
						boxWidth: 7.5,
						usePointStyle: true,
						padding: 16,
					},
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
				tooltips: {
					enabled: false,
					yAlign: "top",
					xAlign: "center",
					title: false,
					callbacks: {},
				},
			},
			defaultLineChartOptions: {
				legend: {
					display: false,
				},
				tooltips: {
					enabled: false,
					yAlign: "top",
					callbacks: {},
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
			},
			evolutionLineChartOptions: {
				maintainAspectRatio: false,
				legend: {
					display: true,
					position: "bottom",
					labels: {
						boxWidth: 7.5,
						usePointStyle: true,
						padding: 16,
					},
				},
				scales: {
					xAxes: [
						{
							gridLines: {
								display: false,
							},
						},
					],
					yAxes: [
						{
							ticks: {
								suggestedMin: 0,
								suggestedMax: 100,
								stepSize: 25,
								callback: (value) => `${value}%`,
							},
						},
					],
				},
				tooltips: {
					enabled: false,
					yAlign: "top",
					callbacks: {},
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
			},
			evolutionLineOptions: {
				borderWidth: 1,
				backgroundColor: "transparent",
				lineTension: 0,
				pointRadius: 4,
			},
			defaultColorsMap: {
				"#F27180": "#F8F8F8",
				"#A5D9C9": "#555555",
				"#FFD773": "#555555",
				"#EFEFEF": "#555555",
				"#000F15": "#F8F8F8",
				"#003E55": "#F8F8F8",
				"#115C7E": "#F8F8F8",
				"#005D80": "#F8F8F8",
				"#007CAC": "#F8F8F8",
				"#589E83": "#F8F8F8",
				"#D1A12A": "#F8F8F8",
				"#F8F8F8": "#F8F8F8",
				"#007DAC": "#F8F8F8",
				"#894200": "#F8F8F8",
				"#3EA083": "#F8F8F8",
				"#A3A4B5": "#F8F8F8",
				"#FF3A52": "#F8F8F8",
				"#000000": "#F8F8F8",
				"#4F0R4F": "#F8F8F8",
				"#C3D7B4": "#F8F8F8",
				"#D7D7A1": "#F8F8F8",
				"#255C7D": "#F8F8F8",
				"#6AAAB9": "#F8F8F8",
				"#82BABF": "#F8F8F8",
				"#AFD7CA": "#F8F8F8",
				"#C9DABC": "#F8F8F8",
				"#DBDAAC": "#F8F8F8",
				"#3C6C89": "#F8F8F8",
				"#7AB2BF": "#F8F8F8",
				"#90C0C4": "#F8F8F8",
				"#B8DBCF": "#F8F8F8",
				"#CEDCC3": "#F8F8F8",
				"#DEDCB6": "#F8F8F8",
				"#517A93": "#F8F8F8",
			},
		},

		tooltip: {
			defaultConfig: {
				trigger: "hover click",
			},
			defaultInfoIconConfig: {
				trigger: "hover manual",
			},
			defaultWarningIconConfig: {
				trigger: "hover manual",
			},
		},

		datepickerOptions: {
			days: [
				"Maandag",
				"Dinsdag",
				"Woensdag",
				"Donderdag",
				"Vrijdag",
				"Zaterdag",
				"Zondag",
			],
			daysShort: ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"],
			monthNames: [
				"Januari",
				"Februari",
				"Maart",
				"April",
				"Mei",
				"Juni",
				"Juli",
				"Augustus",
				"September",
				"Oktober",
				"November",
				"December",
			],
			colors: {
				selected: "#00B7F1",
				inRange: "rgba(0,183,241,0.12)",
				text: "#4d4d4d",
				selectedText: "#4d4d4d",
				inRangeBorder: "none",
				disabled: "transparent",
				hoveredInRange: "transparent",
			},
			texts: {
				cancel: "Annuleer",
			},
		},

		daySelectorOptions: {
			1: "Maandag",
			2: "Dinsdag",
			3: "Woensdag",
			4: "Donderdag",
			5: "Vrijdag",
			6: "Zaterdag",
			7: "Zondag",
		},

		daysShort: {
			1: "Ma",
			2: "Di",
			3: "Wo",
			4: "Do",
			5: "Vr",
			6: "Za",
			7: "Zo",
		},

		binVolumeOptions: [
			{
				value: "0-99",
				label: "0-99 L.",
			},
			{
				value: "100-249",
				label: "100-249 L.",
			},
			{
				value: "250-999",
				label: "250-999 L.",
			},
			{
				value: "1000-2499",
				label: "1000-2499 L.",
			},
			{
				value: "2500",
				label: "+ 2500 L.",
			},
		],

		projects: {
			filterUrlParameters: {
				naam: "name",
				"a-z": "asc",
				"z-a": "desc",
			},
			statusColors: {
				effect_measurement: "#357CA8",
				baseline_measurement: "#B0D8CA",
				started: "#E27882",
				closed: "#D6E08A",
			},
			projectBinAnalyseLabels: {
				fillDegree: "Vullingsgraad",
				dump: "Sluikstort in",
				additionalDump: "Sluikstort rond",
				litter: "Zwerfvuil",
			},
		},

		volunteers: {
			urlDateFormat: "DD-MM-YYYY",

			filterUrlParameters: {
				naam: "name",
				locatie: "location",
				opruimsessies: "cleanUpSessions",
				afstand: "distance",
				richting: "sortType",
				"a-z": "asc",
				"z-a": "desc",
				duratie: "avgDuration",
				verzameld: "collectedTrash",
				meldingen: "incidents",
				actief: "created",
			},

			active_options: [
				{
					label: "Laatste 7 dagen",
					value: "test",
				},
				{
					label: "Laatste 14 dagen",
					value: "test",
				},
				{
					label: "Laatste 30 dagen",
					value: "test",
				},
				{
					label: "Laatste 6 maanden",
					value: "test",
				},
				{
					label: "Laatste jaar",
					value: "test",
				},
			],
		},
		activities: {
			urlDateFormat: "DD-MM-YYYY",

			filterUrlParameters: {
				"duur-sessie": "field_activity_duration",
				"afstand-sessie": "field_activity_distance",
				startadress: "field_activity_start_address",
				date: "created",
				user: "uid.field_user_first_name",
				"uid.field_user_first_name": "user",
			},

			duration_options: [
				{
					operator: "BETWEEN",
					urlKey: "kort",
					label: "Kort (< 30 min.)",
					field: "field_activity_duration",
					name: "duration_short",
					group: "activity_duration",
					value: [0, 1800],
				},
				{
					operator: "BETWEEN",
					urlKey: "medium",
					label: "Medium (30 min. - 2 uur)",
					field: "field_activity_duration",
					name: "duration_medium",
					group: "activity_duration",
					value: [1800, 7200],
				},
				{
					operator: "BETWEEN",
					urlKey: "lang",
					label: "Lang (> 2 uur)",
					field: "field_activity_duration",
					name: "duration_long",
					group: "activity_duration",
					value: [7200, 99999999],
				},
			],

			distance_options: [
				{
					operator: "BETWEEN",
					urlKey: "kort",
					label: "Kort (< 1 km.)",
					field: "field_activity_distance",
					name: "distance_short",
					group: "activity_distance",
					value: [0, 1000],
				},
				{
					operator: "BETWEEN",
					urlKey: "medium",
					label: "Medium (1 Km - 5 Km)",
					field: "field_activity_distance",
					name: "distance_medium",
					group: "activity_distance",
					value: [1000, 5000],
				},
				{
					operator: "BETWEEN",
					urlKey: "lang",
					label: "Lang (> 5 Km)",
					field: "field_activity_distance",
					name: "distance_long",
					group: "activity_distance",
					value: [5000, 99999999],
				},
			],
		},

		incidents: {
			filterResponseTime: 1000,

			archiveFilterOptions: [
				{
					id: "overviewwitharchive",
					label: "Overzicht met archief",
					urlKey: "overzichtmetarchief",
					value: undefined,
				},
				{
					id: "overview",
					label: "Niet gearchiveerd",
					urlKey: "0",
					value: "0",
				},
				{
					id: "archive",
					label: "Gearchiveerd",
					urlKey: "1",
					value: "1",
				},
			],

			// status, team & date need to be arrays, use null to avoid setting default value
			default: {
				filter: {
					archived: 0, // list type id => see archivedFilterOptions
					status: null, // array with status modifiers
					team: null, // array with team ids
					date: null, // array with integers [start, end]
					title: null, // string
				},

				sort: {
					created: {
						type: "desc",
						weight: 0,
					},
				},
			},

			urlDateFormat: "DD-MM-YYYY",

			filterUrlParameters: {
				datum: "created",
				status: "status",
				gewijzigd: "changed",
				sorteren: "sort",
				richting: "sortType",
				type: "listType",
				adres: "title",
				volume: "volume",
				"a-z": "asc",
				"z-a": "desc",
				ploeg: "team",
				overzicht: "overview",
				archief: "archive",
				overzichtmetarchief: "overviewwitharchive",
				team: "team",
				category: "category",
				priority: "priority",
				legal_info: "legal_info",
				report_type: "report_type",
				archived: "archived",
			},

			statusColorsById: {
				fallback: "#f0f0f0",
				25: "#F04E58",
				26: "#FFC000",
				27: "#3EA083",
				28: "#007DAC",
				29: "#8BB01C",
			},

			statusColors: {
				new: "#F04E58",
				to_collect: "#007DAC",
				to_investigate: "#FFC000",
				in_progress: "#3EA083",
				verified: "#dd956e",
				processed: "#8BB01C",
				rejected: "#bfcbd1",
			},

			statusDoneTid: 29,
		},

		incident: {
			defaultStatusTID: 25,
			allowSubmitAfterStep: 1,
			allowRegistrationSubmitAfterStep: 3,
			images: {
				allowedFileTypes: ["image/jpeg", "image/png"],
				jpgQuality: 0.8,
				size: {
					width: 2400,
					height: 1800,
				},
				fixOrientation: true,
				processorVisible: false,
				autoSwapRatio: true, // not implemented yet,
				autoRatio: true,
			},

			export: {
				map: {
					jpgQuality: 1,
					size: {
						width: 800,
						height: 200,
					},
					processorVisible: false,
				},

				smallMap: {
					jpgQuality: 1,
					size: {
						width: 476,
						height: 302,
					},
					processorVisible: false,
				},

				images: {
					jpgQuality: 1,
					size: {
						width: 2400,
						height: 1800,
					},
					processorVisible: false,
				},
			},
		},

		incidentCreation: {
			backupCenter: {
				lat: 50.879085,
				lng: 4.707997,
			},
		},

		garbageBin: {
			// allowSubmitAfterStep: 7,
			images: {
				jpgQuality: 0.6,
				size: {
					width: 2048,
					height: 1500,
				},
				fixOrientation: true,
				processorVisible: false,
				// swapOrientationRatio: true // not implemented yet
			},

			export: {
				map: {
					jpgQuality: 1,
					size: {
						width: 800,
						height: 250,
					},
					processorVisible: false,
				},

				images: {
					jpgQuality: 1,
					size: {
						width: 4069,
						height: 3000,
					},
					processorVisible: false,
				},
			},
			binOpeningValues: [
				{
					val: 14,
					label: "Kleiner dan 15 cm",
				},
				{
					val: 16,
					label: "Groter dan 15 cm",
				},
			],
			binOpeningOptions: [
				{
					value: 14,
					label: "Kleiner dan 15 cm",
				},
				{
					value: 16,
					label: "Groter dan 15 cm",
				},
			],
			binCustomValues: [120, 240, 1300, 2500, 3200, 3350, 4000, 5000],
			binAshtrayValues: [
				{
					value: true,
					title: "Ja",
				},
				{
					value: false,
					title: "Nee",
				},
			],
			fillDegreeLabels: {
				empty: "Leeg",
				half_full: "Halfvol",
				full: "Vol",
				overflowing: "Overvol",
			},
		},

		measurements: {
			fillDegreeValues: [
				{
					min: 0,
					max: 10,
					dbVal: 0,
					label: "Leeg",
					apiName: "leeg",
					image: "icons/bin-empty.svg",
					percentage: "0",
					color: "#007DAC",
				},
				{
					min: 40,
					max: 60,
					dbVal: 50,
					label: "Halfvol",
					apiName: "halfvol",
					image: "icons/bin-half-full.svg",
					percentage: "50",
					color: "#379CB6",
				},
				{
					min: 90,
					max: 100,
					dbVal: 100,
					label: "Vol",
					apiName: "vol",
					image: "icons/bin-full.svg",
					percentage: "100",
					color: "#70BCC0",
				},
				{
					min: 100,
					max: 130,
					dbVal: 125,
					label: "Overvol",
					apiName: "overvol",
					image: "icons/bin-overflow.svg",
					percentage: "> 100",
					color: "#A5D9C9",
				},
			],
			illegalDumpList: [
				{
					val: 0,
					label: "Nee",
					apiName: "none",
					image: "icons/dump-0.svg",
				},
				{
					val: 1,
					label: "Ja, minder dan de helft",
					apiName: "lessthanten",
					image: "icons/dump-1.svg",
				},
				{
					val: 2,
					label: "Ja, meer dan de helft",
					apiName: "morethanten",
					image: "icons/dump-2.svg",
				},
				{
					val: -1,
					label: "Niet gemeten",
					apiName: "didnotmeasure",
					image: "icons/not-measured.svg",
				},
			],
			illegalDumpCombinedList: [
				{
					val: 0,
					label: "Nee",
					apiName: "none",
					image: "icons/dump-0.svg",
				},
				{
					val: [1, 2],
					label: "Ja",
					apiName: "yes",
					image: "icons/dump-1.svg",
				},
				{
					val: -1,
					label: "Niet gemeten",
					apiName: "didnotmeasure",
					image: "icons/not-measured.svg",
				},
			],
			cigarettesList: [
				{
					val: 1,
					label: "Geen zwerfvuil",
					labelShort: "Geen",
					apiName: "none",
					image: "icons/cigarettes-0.svg",
				},
				{
					val: 5,
					label: "Minder dan 10 stukken zwerfvuil",
					labelShort: "< 10",
					apiName: "lessthanten",
					image: "icons/cigarettes-1.svg",
				},
				{
					val: 15,
					label: "Meer dan 10 stukken zwerfvuil",
					labelShort: "> 10",
					apiName: "morethanten",
					image: "icons/cigarettes-2.svg",
				},
				{
					val: 0,
					label: "Niet gemeten",
					labelShort: "Niet gemeten",
					apiName: "didnotmeasure",
					image: "icons/not-measured.svg",
				},
			],
			default: {
				sort: {
					created: {
						type: "desc",
						weight: 0,
					},
				},
			},

			filterUrlParameters: {
				created: "datum",
				changed: "gewijzigd",
				desc: "z-a",
				asc: "a-z",
			},
		},

		analyseButtons: {
			BUTTONS_GROUP_LIST: "tableList",
			BUTTONS_GROUP_CHART: "analyse",
			BUTTONS_GROUP_MAP: "map",
		},

		export: {
			incident: {
				filename: "Melding",
			},

			incidentCollection: {
				filename: "Meldingen",
			},

			measurementCollection: {
				filename: "Metingen-overzicht",
			},

			measurementSummary: {
				filename: "Metingen-samenvatting",
			},

			measurement: {
				filename: "Meting",
			},

			binCollection: {
				filename: "Afvalpunt-inventaris",
			},

			bin: {
				filename: "Afvalpunt",
			},

			volunteerCollection: {
				filename: "actieve-vrijwilligers",
			},

			activityCollection: {
				filename: "opruimsessies",
			},

			projectMeasurementCollection: {
				filename: "project-metingen",
			},

			eventCollection: {
				filename: 'opruimacties'
			},
			event: {
				filename: 'opruimactie'
			},
			eventParticipants: {
				filename: 'opruimactie-deelnemers'
			},

			groupsCollection: {
				filename: 'groepen'
			}
		},

		creationFlow: {
			sendAtStep: 2,
		},

		dataSet: {
			showTiledAt: 770,
			autoResetSortDirection: true, // reset column sort to asc when other header is clicked
			defaultSort: {
				by: "created",
				type: "desc",
			},
		},

		factors: {
			literToGarbageBags: 1 / 60,
			garbageBagsToLiter: 60,
			maxGarbageBagsValue: 11 * 60,
		},

		filtersNamesInStore: {
			FILTERS_INCIDENTS_DETAILS: "FILTERS_INCIDENTS_DETAILS",
			FILTERS_INCIDENTS_HEAT_GMAP: "FILTERS_INCIDENTS_HEAT_GMAP",
			FILTERS_INCIDENTS_CATEGORIES: "FILTERS_INCIDENTS_CATEGORIES",
			FILTERS_INCIDENTS_START: "FILTERS_INCIDENTS_START",

			FILTERS_MEASUREMENTS_GENERAL: "FILTERS_MEASUREMENTS_GENERAL",
			FILTERS_MEASUREMENTS_INVENTORY: "FILTERS_MEASUREMENTS_INVENTORY",
			FILTERS_MEASUREMENTS_LOCATION: "FILTERS_MEASUREMENTS_LOCATION",
		},

		externalApis: {
			geoPunt: "https://geopunt.be/geolocation/Location",
		},

		iconsPath: "/images/icons/",
		imagesPath: "/images/",

		//filter fields to init on dataModules
		dataModuleFilters: {
			projectsDM: {
				involvedParties: [],
				cleaningMethods: [],
				locationTypes: [],
				statuses: [],
				fractions: [],
				title: "",
				hasExperienceScan: "",
				isCleanliness: "",
			},
			projectsReportDM: {
				status: [],
				locationType: [],
				hasVolunteers: "",
				isEventual: "",
				countingMethods: [],
				fractions: [],
				startDate: "",
				endDate: "",
				hasExperienceScan: "",
			},
			projectsMapDM: {
				involvedParties: [],
				cleaningMethods: [],
				locationTypes: [],
				statuses: [],
				fractions: [],
				title: "",
			},
			projectMeasurementsDM: {
				type: "",
				measuringStrip: [],
			},
			candidateMeasuresDM: {
				q: "",
				locationTypes: [],
				fractions: [],
				pillars: [],
				effort: [],
				isPopular: "",
				cleaningMethods: [],
				hasInfluenceOnExperience: "",
			},
			projectBinsDM: {
				postalCode: [],
				locationType: [],
				fraction: [],
				surface: [],
				material: [],
				color: [],
				volume: {},
				analysisRange: {},
				lidsize: "",
				damageAmount: [],
				emptyingFrequency: [],
				status: [],
				lastMeasurement: [],
				firstMeasurement: [],
				hasRemarks: "",
				remarkStatus: [],
			},
			binsDM: {
				postalCode: [],
				locationType: [],
				fraction: [],
				surface: [],
				material: [],
				color: [],
				volume: {},
				lidSize: "",
				damageAmount: [],
				emptyingFrequency: [],
				status: [],
				hasRemarks: "",
				remarkStatus: [],
				lastMeasurement: [],
				firstMeasurement: [],
				query: "",
				analysis: {
					fillDegree: {
						value: [],
						minPercentage: 1,
						maxPercentage: 100,
						type: "fillDegree",
					},
					dump: {
						value: [],
						minPercentage: 1,
						maxPercentage: 100,
						type: "dump",
					},
					litter: {
						value: [],
						minPercentage: 1,
						maxPercentage: 100,
						type: "litter",
					},
					additionalDump: {
						value: [],
						minPercentage: 1,
						maxPercentage: 100,
						type: "additionalDump",
					},
				},
				analysisRange: {},
				groups: [],
				measuringPeriods: [],
				validityRange: {},
				startDate: "",
				endDate: "",
			},
			activitiesDM: {
				postalCode: [],
				duration: [],
				distance: [],
				events: [],
				startDate: '',
				endDate: '',
				userName: ''
			},
			activitiesListDM: {
				postalCode: [],
				duration: [],
				distance: [],
				events: [],
				startDate: '',
				endDate: '',
				userName: ''
			},
			groupsDM: {
				search: '',
				postalCodes: [],
				groupTypes: [],
			},
			userGroupsDM: {
				search: '',
				postalCode: [],
				groupTypes: [],
			},
			groupMembersDM: {
				search: '',
				fullName: '',
			},
			groupPostsDM: {
				search: '',
			},
			groupAdminsDM: {
				search: '',
			},
			eventsDM: {
				query: '',
				groups: [],
				postalCodes: [],
			},
			eventParticipantsDM: {
				search: ''
			},
			groupEventsDM: {
				search: ''
			},
			eventActivitiesDM: {
				user: '',
				created: '',
				startAddress: '',
				distance: '',
			},
			eventHeatmapActivitiesDM: {
				postalCode: [],
				duration: [],
				distance: [],
				event: [],
				startDate: '',
				endDate: '',
				user: ''
			},
			volunteersDM: {
				fullName: '',
				startDate: '',
				endDate: '',
				search: '',
			},
			binMeasurementsDM: {
				date: '',
				startDate: '',
				endDate: '',
				search: '',
				bins: [],
				postalCodes: [],
				locationTypes: [],
				surfaces: [],
				fillDegrees: [],
				dumpStatuses: [],
				additionalDumpStatuses: [],
				litterStatuses: [],
			}
		},

		icons: {
			fillDegree: {
				empty: "bin-empty.svg",
				half_full: "bin-half-full.svg",
				full: "bin-full.svg",
				overflowing: "bin-overflow.svg",
			},
		},
	};

	// process functions
	// add libs
	Config.libs = {
		exif: {
			url: "https://cdnjs.cloudflare.com/ajax/libs/exif-js/2.3.0/exif.min.js",
		},

		gmap: {
			url: "//maps.googleapis.com/maps/api/js",
			passCallback: true,
			callbackParam: "callback",
			parameters: {
				key: Config.maps.key,
				libraries: Config.maps.libraries.join(","),
				language: "NL",
				region: "BE",
			},
		},

		jzip: {
			url: "https://cdnjs.cloudflare.com/ajax/libs/jszip/3.2.0/jszip.min.js",
		},

		xlsx: {
			url: "https://cdnjs.cloudflare.com/ajax/libs/xlsx/0.15.1/xlsx.full.min.js",
			// dependencies: ['jzip']
		},

		pdf: {
			url: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.54/pdfmake.min.js",
		},

		loadImage: {
			url: "https://cdnjs.cloudflare.com/ajax/libs/blueimp-load-image/2.23.0/load-image.all.min.js",
		},

		paged: {
			url: "https://unpkg.com/pagedjs/dist/paged.polyfill.js",
		},
	};

	// return
	return Config;
})();

window.c = Config;

export default Config;
