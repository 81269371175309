// imports
import JsonApiModel from '$dependencies/JsonApiModel';

// private static properties
const API_DICTIONARY = {
	uuid: 'id',
	field_incident_categories_legal: 'categoriesLegal',
	field_incident_categories_volume: 'categoriesVolume',
};

const FIELDS = {
	id: { type: String, default: '', identifier: true },
	name: { type: String, default: '' },
	parent: { type: String, default: null },
	categoriesLegal: { type: Boolean, default: false },
	categoriesVolume: { type: Boolean, default: false },
	volumeStepRequired: { type: Boolean, default: false },
	legalStepRequired: { type: Boolean, default: false },
};

// class definition
export default class CategoryModel extends JsonApiModel {
	// constructor
	constructor(args = {}) {
		// clone args to do some modifications
		args = Object.assign({}, args);
		args.fields = FIELDS;
		args.entityType = 'taxonomy_term--incident_categories';

		// json api settings
		args.jsonApi = Object.assign(args.jsonApi || {}, {
			dictionary: API_DICTIONARY,
		});

		// call super constructor
		super(args);
	}

	// methods
	parse(data) {
		// hack into data to move relationship "parent" to attribute desired parsing!
		if (data.data.relationships) {
			let id = null;
			if (data.data.relationships.parent) {
				id = data.data.relationships.parent.data[0].id;
				if (id === 'virtual') id = null; // 'virtual' == null!
			}

			data.data.attributes.parent = id;
			delete data.data.relationships.parent;
		}

		// debugger;

		return super.parse(data);
	}

	destroy() {}

	// utility methods

	// getters & setters
}
